<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
          lg="8"
          :style="cssOptions"
          class="d-none d-lg-flex align-items-center p-5"
      >

      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <div class="mb-5">
            <img style="max-width: 250px;" src="@/assets/images/rewards_logo.png">
          </div>
          <b-card-text class="mb-2">
            Please enter your username and password
          </b-card-text>

          <b-card-text class="mb-2">
            <div class="alert alert-danger p-3" v-if="errorMessage">
              {{ errorMessage }}
            </div>
          </b-card-text>

          <!-- form -->
          <validation-observer
              ref="loginForm"
              #default="{invalid}"
          >
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent="loginUser"
            >
              <!-- email -->
              <b-form-group
                  label="Email"
                  label-for="login-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                >
                  <b-form-input
                      id="login-email"
                      v-model="username"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { mapGetters, mapActions } from "vuex";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      errorMessage:'',
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      cssOptions:{
        backgroundImage: `url( ${require('@/assets/images/rewards_africa_cover.png')} )`,
        backgroundSize: 'cover',
        backgroundBlendMode: 'multiply',
        backgroundColor: 'rgba(0, 0, 0,0.5)'
      },
      // validation rules
      required,
      email,
      userData: {
        id: 0,
        fullName: "",
        username: "johndoe",
        avatar: "/img/13-small.d796bffd.png",
        email: "admin@demo.com",
        role: "admin",
        ability: [
          {
            action: "manage",
            subject: "all"
          }
        ],
        extras: {
          eCommerceCartItemsCount: 5
        }
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions(["login"]),
    loginUser() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.login({
            email: this.username,
            password: this.password,
          })
              .then(response => {


                this.userData.id = response.userId;
                this.userData.fullName = response.full_names;
                this.userData.username = this.username;
                this.userData.email = this.username;

                useJwt.setToken(response.jwttoken)
                useJwt.setRefreshToken(response.jwttoken)
                localStorage.setItem('userData', JSON.stringify(this.userData))
                this.$ability.update(this.userData.ability)


                // ? This is just for demo purpose as well.
                // ? Because we are showing eCommerce app's cart items count in navbar
                this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.userData.extras.eCommerceCartItemsCount);

                if(localStorage.getItem('role') == "Merchant" || localStorage.getItem('role') == "Cashier"){
                  // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                  this.$router.replace(this.$route.query.from ? this.$route.query.from : "/apps/transactions")
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `Welcome ${this.userData.fullName || this.userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: `You have successfully logged in as a ${this.userData.role}.`,
                          },
                        })
                      })
                }
                else{
                  // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                  this.$router.replace(this.$route.query.from ? this.$route.query.from : "/")
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `Welcome ${this.userData.fullName || this.userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: `You have successfully logged in as ${this.userData.role}.`,
                          },
                        })
                      })
                }


              })
              .catch(error => {
                this.errorMessage = error.message;
                //console.log(error);
                //this.$refs.loginForm.setErrors(error.response.data.error)
              })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
